// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },

        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
          fontWeight: "400",
          transition: ".2s all !important",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            boxShadow: theme.customShadows.primary,
            transform: "scale(1.01)",
          },
        },

        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
      },
    },
  };
}
