import { Box } from "@mui/material";
import React from "react";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import { PATH_USER } from "src/routes/paths";
import GetRankAchievers from "./getRankAchievers";

const RankAchievers = () => {
  return (
    <div>
      <GetRankAchievers />
    </div>
  );
};

export default RankAchievers;
