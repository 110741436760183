import { Box, Container, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Logo from "src/components/logo";
import useGetLogo from "src/components/logo/hooks/use-logo";

const AuthLayout = ({ children }) => {
  const logo = useGetLogo();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        background: theme.palette.background.main,
        py: { xs: "20px", md: "60px" },
        pb: { xs: 1, md: 2 },
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      }}
    >
      <Container
        fixed
        maxWidth="sm"
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: { xs: 1, sm: 2, md: 3 },
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            padding: { xs: 2, md: 5 },
            width: "100%",
            maxWidth: { xs: "100%", md: "sm" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              mb: 3,
              mt: 2,
              justifyContent: "flex-start",
            }}
          >
            <Logo />
          </Box>
          {children}
        </Paper>
      </Container>
    </Box>
  );
};

export default AuthLayout;
