import { LoadingButton } from "@mui/lab";
import { Box, Card, Typography } from "@mui/material";
import UsersSearch from "src/components/autoComplete/users";
import EWalletAmountTypes from "src/components/e-wallet-amount-types";
import { FormProvider, RHFSelect } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import Map from "src/components/map";
import Translate from "src/components/translate";
import useRankList from "../hooks/useRankList";
import { capitalCase } from "change-case";
import { useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

const FilterCard = ({ fetchData, methods, setNewValue }) => {
  const onSubmit = async (inputData) => await fetchData(inputData);
  const { state } = useRankList();
  const { data } = state;
  const { id } = useParams();
  const { control, setValue, watch } = useForm();
  const selectedRankId = watch("rank_id");
  const selectedRank = data.find((rank) => rank.id === parseInt(id));

  useEffect(() => {
    if (selectedRank) {
      setValue("rank_id", selectedRank.id);
    }
  }, [selectedRank, setValue]);
  const {
    formState: { isSubmitting },
  } = methods;
  return (
    <div>
      <Card sx={{ p: 3 }}>
        <Box>
          <FormProvider
            methods={methods}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                marginTop: 3,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(5, 1fr)",
                },
              }}
            >
              <RHFDatePicker
                name="start_date"
                label="date.start"
                size="small"
              />
              <RHFDatePicker name="end_date" label="date.end" size="small" />
              <UsersSearch
                name="user_id"
                props={{ size: "small" }}
                type="all"
              />
              <Controller
                name="rank_id"
                control={control}
                render={({ field }) => (
                  <RHFSelect
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Rank Name"
                    size="small"
                    value={field.value || ""}
                    onChange={(e) => {
                      setNewValue(e.target.value);
                      field.onChange(e);
                    }}
                  >
                    <option value="all">All</option>
                    {data.map(({ id, rank_name }) => (
                      <option key={id} value={id}>
                        {capitalCase(rank_name || "")}
                      </option>
                    ))}
                  </RHFSelect>
                )}
              />
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                name="get-report"
              >
                <Translate>report.get</Translate>
              </LoadingButton>
            </Box>
          </FormProvider>
        </Box>
      </Card>
    </div>
  );
};

export default FilterCard;
