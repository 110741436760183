import LoginForm from "src/pages/auth/Login/components/MainSection/login";
import AuthLayout from "./components/auth-layout";
import Message from "./components/message";
import NoAccountSection from "./components/no-account-section";

const MainSection = ({
  setWalletAddress,
  walletAddress,
  connectWallet,
  adminLogin,
}) => {
  return (
    <AuthLayout>
      <Message adminLogin={adminLogin} />

      <LoginForm
        adminLogin={adminLogin}
        connectWallet={connectWallet}
        walletAddress={walletAddress}
        setWalletAddress={setWalletAddress}
      />
    </AuthLayout>
  );
};

export default MainSection;
