import React, { useState } from "react";
import DataList from "./dataList";
import useRankAchieversList from "../hooks/useRankAchiversList";
import { Box, Grid } from "@mui/material";
import Page from "src/components/Page";
import { PATH_USER } from "src/routes/paths";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { useForm } from "react-hook-form";
import FilterCard from "./FilterCard";
import { yupResolver } from "@hookform/resolvers/yup";
import serializeDate from "src/utils/serialize-date";
import { object, string } from "yup";

import moment from "moment";
const defaultValues = {
  start_date: null,
  end_date: null,
  from_id: null,
};

const schema = object().shape({
  start_date: string()
    .test("is-valid", "errors.date.valid_date.test", (v) => {
      if (v === null) return true;
      return moment(v, "YYYY/MM/DD").isValid();
    })
    .test("is-valid", "errors.date.valid_start.test", (v, ctx) => {
      if (v === null) return true;
      if (ctx.parent.end_date === null) return true;
      return (
        moment(ctx.parent.end_date, "YYYY/MM/DD").diff(
          moment(v, "YYYY/MM/DD")
        ) >= 0
      );
    })
    .transform((v) => serializeDate(v))
    .nullable(),
  end_date: string()
    .test("is-valid", "errors.date.valid_date.test", (v) => {
      if (v === null) return true;
      return moment(v, "YYYY/MM/DD").isValid();
    })
    .test("is-valid", "errors.date.valid_end.test", (v, ctx) => {
      if (v === null) return true;
      if (ctx.parent.start_date === null) return true;
      return (
        moment(ctx.parent.start_date, "YYYY/MM/DD").diff(
          moment(v, "YYYY/MM/DD")
        ) <= 0
      );
    })
    .transform((v) => serializeDate(v))
    .nullable(),
});
const GetRankAchievers = () => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const filter = methods.watch();
  const [newValue, setNewValue] = useState();

  const { state, rowStart, fetchData, ...rest } = useRankAchieversList(
    filter,
    newValue
  );

  return (
    <div>
      <Page title={"Rank Achievers"}>
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={"Rank Achievers"}
            links={[
              { name: "global.dashboard", href: PATH_USER.root },

              { name: "Rank Achievers" },
            ]}
          />
          <Grid item xs={12} md={12}>
            <FilterCard
              setNewValue={setNewValue}
              methods={methods}
              fetchData={async (filter) => {
                await fetchData(1, filter);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} mt={1}>
            <DataList state={state} rowStart={rowStart} {...rest} />
          </Grid>
        </Box>
      </Page>
    </div>
  );
};

export default GetRankAchievers;
