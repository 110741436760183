import Page from "src/components/Page";
import Header from "./components/Header";
import MainSection from "./components/MainSection";
import detectEthereumProvider from "@metamask/detect-provider";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
const Login = ({ adminLogin }) => {
  const isSubAdmin = localStorage.getItem("isSubAdmin") === "true";
  const [walletAddress, setWalletAddress] = useState("");

  // useEffect(() => {
  //   const checkWalletConnection = async () => {
  //     const provider = await detectEthereumProvider();
  //     if (provider) {
  //       const accounts = await provider.request({ method: "eth_accounts" });
  //       if (accounts.length > 0) {
  //         setWalletAddress(accounts[0]);
  //       }
  //     }
  //   };
  //   checkWalletConnection();
  // }, []);
  // useEffect(() => {
  //   const handleAccountsChanged = (accounts) => {
  //     if (accounts.length > 0) {
  //       setWalletAddress(accounts[0]);
  //       localStorage.setItem("walletAddress", accounts[0]);
  //     } else {
  //       setWalletAddress(null);
  //       localStorage.removeItem("walletAddress");
  //     }
  //   };

  //   const handleChainChanged = () => {
  //     window.location.reload();
  //   };

  //   window.ethereum.on("accountsChanged", handleAccountsChanged);
  //   window.ethereum.on("chainChanged", handleChainChanged);

  //   return () => {
  //     window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
  //     window.ethereum.removeListener("chainChanged", handleChainChanged);
  //   };
  // }, []);
  // const connectWallet = async () => {
  //   try {
  //     const provider = await detectEthereumProvider();

  //     if (provider) {
  //       const isTrustWallet = provider.isTrust;
  //       const isMetaMask = provider.isMetaMask;

  //       await provider.request({ method: "eth_requestAccounts" });
  //       const accounts = await window.ethereum.request({
  //         method: "eth_accounts",
  //       });
  //       const chainId = await provider.request({ method: "eth_chainId" });
  //       setWalletAddress(accounts[0]);
  //       if (isTrustWallet) {
  //         console.log("Connected with Trust Wallet");
  //       } else if (isMetaMask) {
  //         console.log("Connected with MetaMask");
  //       }
  //     } else {
  //       window.open("https://trustwallet.com/", "_blank");
  //       console.warn("Ethereum provider not detected.");
  //     }
  //   } catch (err) {
  //     console.error("Failed to connect:", err);
  //   }
  // };

  if (isSubAdmin) {
    const menu_lists = JSON.parse(localStorage.getItem("menu"));
    const [menu] = menu_lists;
    const { path, children } = menu?.items[0] || {};

    if (path.includes("dashboard")) {
      window.location = children[0]?.path;
      return null;
    }
    window.location = path;
    return null;
  }

  return (
    <Page
      title="Login"
      sx={{
        height: "100%",
      }}
    >
      {/* {<Header />} */}

      <MainSection
        adminLogin={adminLogin}
        setWalletAddress={setWalletAddress}
        // connectWallet={connectWallet}
        walletAddress={walletAddress}
      />
    </Page>
  );
};

export default Login;
