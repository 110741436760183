import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import useGetLogo from "./hooks/use-logo";
import { useTheme } from "@mui/material";
import light from "src/images/GN dark mode.png";
const Logo = ({ disabledLink = false }) => {
  const logo = useGetLogo();
  const { palette } = useTheme();
  const isDark = palette.mode === "dark";
  if (disabledLink) {
    return (
      <img
        style={{ width: "200px", height: "auto", maxHeight: "64px" }}
        src={logo}
      />
    );
  }

  return (
    <RouterLink>
      {isDark ? (
        <img style={{ width: "200px", height: "auto" }} src={light} />
      ) : (
        <img style={{ width: "200px", height: "auto" }} src={logo} />
      )}
    </RouterLink>
  );
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
