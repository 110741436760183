import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import { useAppConfig } from "src/store/app-config";
import buildPath from "src/utils/build-path";
import { clearSession, setSession } from "src/utils/jwt";
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";
import Login from "../pages/auth/Login";
import axios from "axios";
import { useAccount } from "wagmi";
import useGetCurrency from "src/layouts/shared/header/components/currency-popover/hooks/use-get-currency";
import { useTranslation } from "react-i18next";
import { HOST_API, TIME_OUT } from "src/config";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      clearSession();
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

export const AdminGuard = ({ children }) => {
  const { isAdmin, isSubAdmin } = useAuth();

  if (isAdmin || isSubAdmin) {
    return <>{children}</>;
  }
  return <Navigate to="/user" />;
};

export const SubAdminGuard = ({ children }) => {
  const { isSubAdmin } = useAuth();

  if (isSubAdmin) {
    return <Navigate to={PATH_DASHBOARD.dashboard.root} />;
  }

  return <>{children}</>;
};

export const UserGuard = ({ children }) => {
  const { isAdmin, isSubAdmin, logout } = useAuth();
  const impersonate = localStorage.getItem("isImpersonate");

  const IDLE_TIMEOUT = TIME_OUT; // 15 minutes in milliseconds 60000
  const handleLogout = () => {
    if (impersonate === "true") {
      // Do nothing if impersonate is true
    } else {
      logout();
    }
  };
  const { reset } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: handleLogout,
    debounce: 250, // Optional: debounce idle events
  });

  useEffect(() => {
    const handleActivity = () => {
      reset();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("click", handleActivity);
    window.addEventListener("touchstart", handleActivity);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.addEventListener("touchstart", handleActivity);
    };
  }, [reset]);
  // const navigate = useNavigate();

  // const { address, isConnected } = useAccount();
  // const [currentAddress, setCurrentAddress] = useState(address);
  // const { i18n } = useTranslation();
  // const fetchCurrency = useGetCurrency();

  // const Login = async (address) => {
  //   const formData = new FormData();
  //   formData.append("wallet_address", address);

  //   try {
  //     const BASE_URL = `${HOST_API}api/user-register`;
  //     const { status, data } = await axios.post(BASE_URL, formData, {
  //       headers: {
  //         "Accept-Language": i18n.language,
  //       },
  //     });
  //     if (status === 200) {
  //       const {
  //         access_token,
  //         user,
  //         message,
  //         secret,
  //         menu_lists,
  //         package_status,
  //       } = data;
  //       localStorage.setItem("menu", JSON.stringify(menu_lists));
  //       await getUser();
  //       navigate("/user/dashboard");
  //       fetchCurrency();
  //       if (!secret) {
  //         localStorage.setItem("menu", JSON.stringify(menu_lists));
  //         localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
  //         localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));
  //         localStorage.setItem("package_status", Boolean(package_status));
  //         setSession(access_token);

  //         if (Boolean(user.is_sub_admin)) {
  //           // setPlan(reqObj.plan);
  //           const [menu] = menu_lists;
  //           const { path, children } = menu?.items[0] || {};
  //           if (path.includes("dashboard")) {
  //             window.location = children[0]?.path;
  //             return;
  //           }
  //           window.location = path;
  //           return;
  //         }

  //         return {
  //           status: true,
  //           data: user.username,
  //           isAdmin: Boolean(user.is_super_admin),
  //           isSubAdmin: Boolean(user.is_sub_admin),
  //         };
  //       } else if (secret) {
  //         return { status: false, secret };
  //       } else {
  //         return { status: false, message };
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     clearSession();
  //     navigate("/auth/login");
  //     // Object.entries(err.response.data.errors).forEach(([k, v]) =>
  //     //   setError(k, { message: v[0] })
  //     // );
  //   }
  // };
  // useEffect(() => {
  //   if (isConnected && address !== currentAddress) {
  //     // console.log(`Wallet address changed: ${currentAddress} -> ${address}`);
  //     setCurrentAddress(address);
  //     clearSession();
  //     if (address) {
  //       Login(address);
  //     }
  //   }
  // }, [address, isConnected, currentAddress]);

  const packageStatus = JSON.parse(localStorage.getItem("package_status"));
  const { pathname } = useLocation();

  if (
    packageStatus &&
    pathname !== PATH_USER.onlineStore.productSubscription.packages.root &&
    pathname !== PATH_USER.onlineStore.productSubscription.checkout &&
    pathname !==
      buildPath(PATH_USER.onlineStore.productSubscription.checkout, "payment")
  ) {
    return (
      <Navigate to={PATH_USER.onlineStore.productSubscription.packages.root} />
    );
  }

  if (isAdmin || isSubAdmin) {
    return <Navigate to="/admin" />;
  }

  return <>{children}</>;
};

export const HideLeads = ({ children }) => {
  const { config } = useAppConfig();

  if (Object.keys(config).length > 0) {
    const leadsEnable = Boolean(config?.leads_enable?.status);

    if (leadsEnable) return <>{children}</>;

    return <Navigate to="/404" replace />;
  }

  return <LoadingScreen />;
};
