import React from "react";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from "src/config";
const useRealTimeNotifications = () => {
  const { user } = useAuth();
  const { id } = user || {};
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (!id) return; // Exit if there is no user ID
    Pusher.logToConsole = true;
    const stringId = String(id);
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_CLUSTER,
      encrypted: true,
    });

    const channel = pusher.subscribe(stringId);

    channel.bind("notification", function (data) {
      setNotifications((prev) => [...prev, data]);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [id]);

  return { notifications, setNotifications };
};

export default useRealTimeNotifications;
