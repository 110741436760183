import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import ImpersonationBanner from "src/components/impersonationBanner";
import { HEADER } from "src/config";

export default () => {
    const { palette } = useTheme();
    return (
        <Box
            component="main"
            sx={{
                background: palette.background.main,
                px: { lg: 2 },
                pt: {
                    xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                    lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
                },
                pb: {
                    xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                    lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
                },
            }}
        >
            <ImpersonationBanner />
            <Outlet />
        </Box>
    );
};
