import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default ({ children }) => {
    const { palette } = useTheme();
    return (
        <Box
            sx={{
                background: palette.background.main,
                display: { lg: "flex" },
                minHeight: { lg: 1 },
            }}
        >
            {children}
        </Box>
    );
};
