import { GoogleOAuthProvider } from "@react-oauth/google";
import detectEthereumProvider from "@metamask/detect-provider";
import { createContext, useEffect, useState } from "react";
import NotistackProvider from "./components/NotistackProvider";
import { ProgressBarStyle } from "./components/ProgressBar";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { ChartStyle } from "./components/chart";
import FloatingAction from "./components/floating-action";
import Settings from "./components/settings";
import Ternary from "./components/ternary";
import Visitor from "./components/visitor";
import { ENABLE_VISITOR_MODE, GOOGLE_CLIENT_ID } from "./config";
import useAuth from "./hooks/useAuth";
import useGetCurrency from "./layouts/shared/header/components/currency-popover/hooks/use-get-currency";
import Router from "./routes";
import { useAppConfig } from "./store/app-config";
import ThemeProvider from "./theme";
import axiosInstance from "./utils/axios";
import { getSession } from "./utils/jwt";

const fetchBrandSettings = async () => {
  try {
    const { data, status } = await axiosInstance("api/company-logos");
    if (status === 200) {
      const { logo, side_bar_logo, favicon } = data.data;
      if (logo) {
        localStorage.setItem("logo", logo);
        localStorage.setItem("side_bar_logo", logo);
        localStorage.setItem("favicon", favicon);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export default function App() {
  const [walletAddress, setWalletAddress] = useState("");

  const fetchCurrency = useGetCurrency();
  const { config, setConfig } = useAppConfig();
  const { getUser, user, isAdmin, isSubAdmin } = useAuth();
  useEffect(() => {
    if (user && !Object.keys(user).length) {
      getUser();
      fetchCurrency(isAdmin || isSubAdmin);
      return;
    }
  }, [user]);

  useEffect(() => {
    const fetchAppConfig = async () => {
      try {
        const { data } = await axiosInstance("api/config-settings");
        const test = data.data.reduce((acc, curr) => {
          const { code, status, value } = curr || {};

          return {
            ...acc,
            [code]: { status: Boolean(status), value },
          };
        }, {});
        setConfig(test);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAppConfig();
  }, []);

  useEffect(() => {
    fetchBrandSettings();
  }, []);

  const isLoggedIn = getSession();
  // const WalletContext = createContext();
  // useEffect(() => {
  //   const checkWalletConnection = async () => {
  //     const provider = await detectEthereumProvider();
  //     if (provider) {
  //       window.ethereum = provider; // Set the global provider
  //       const accounts = await provider.request({ method: "eth_accounts" });
  //       if (accounts.length > 0) {
  //         setWalletAddress(accounts[0]);
  //       }
  //     }
  //   };
  //   checkWalletConnection();
  // }, []);

  // useEffect(() => {
  //   const handleAccountsChanged = (accounts) => {
  //     if (accounts.length > 0) {
  //       setWalletAddress(accounts[0]);
  //       localStorage.setItem("walletAddress", accounts[0]);
  //     } else {
  //       setWalletAddress(null);
  //       localStorage.removeItem("walletAddress");
  //     }
  //   };

  //   const handleChainChanged = () => {
  //     window.location.reload();
  //   };

  //   if (window.ethereum) {
  //     window.ethereum.on("accountsChanged", handleAccountsChanged);
  //     window.ethereum.on("chainChanged", handleChainChanged);
  //   }

  //   return () => {
  //     if (window.ethereum) {
  //       window.ethereum.removeListener(
  //         "accountsChanged",
  //         handleAccountsChanged
  //       );
  //       window.ethereum.removeListener("chainChanged", handleChainChanged);
  //     }
  //   };
  // }, []);

  // const connectWallet = async () => {
  //   try {
  //     const provider = await detectEthereumProvider();

  //     if (provider) {
  //       window.ethereum = provider; // Set the global provider
  //       await provider.request({ method: "eth_requestAccounts" });
  //       const accounts = await provider.request({ method: "eth_accounts" });
  //       setWalletAddress(accounts[0]);

  //       // Optional: Identify the provider
  //       const isTrustWallet = provider.isTrust;
  //       const isMetaMask = provider.isMetaMask;
  //       if (isTrustWallet) {
  //         console.log("Connected with Trust Wallet");
  //       } else if (isMetaMask) {
  //         console.log("Connected with MetaMask");
  //       }
  //     } else {
  //       window.open("https://trustwallet.com/", "_blank");
  //       console.warn("Ethereum provider not detected.");
  //     }
  //   } catch (err) {
  //     console.error("Failed to connect:", err);
  //   }
  // };
  // useEffect(() => {
  //   connectWallet();
  // });

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ThemeProvider>
        {/* <WalletContext.Provider value={{ walletAddress, connectWallet }}> */}
        <ThemeColorPresets>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <Router />
                {/* <FloatingAction /> */}

                <Ternary
                  when={ENABLE_VISITOR_MODE && isLoggedIn}
                  then={<Visitor />}
                />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeColorPresets>
        {/* </WalletContext.Provider> */}
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
