export const countries = [
  {
    name: "Afghanistan",
    alpha_2: "AF",
    alpha_3: "AFG",
    "country-code": "004",
    "iso_3166-2": "ISO 3166-2:AF",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Åland Islands",
    alpha_2: "AX",
    alpha_3: "ALA",
    "country-code": "248",
    "iso_3166-2": "ISO 3166-2:AX",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Albania",
    alpha_2: "AL",
    alpha_3: "ALB",
    "country-code": "008",
    "iso_3166-2": "ISO 3166-2:AL",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Algeria",
    alpha_2: "DZ",
    alpha_3: "DZA",
    "country-code": "012",
    "iso_3166-2": "ISO 3166-2:DZ",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "American Samoa",
    alpha_2: "AS",
    alpha_3: "ASM",
    "country-code": "016",
    "iso_3166-2": "ISO 3166-2:AS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Andorra",
    alpha_2: "AD",
    alpha_3: "AND",
    "country-code": "020",
    "iso_3166-2": "ISO 3166-2:AD",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Angola",
    alpha_2: "AO",
    alpha_3: "AGO",
    "country-code": "024",
    "iso_3166-2": "ISO 3166-2:AO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Anguilla",
    alpha_2: "AI",
    alpha_3: "AIA",
    "country-code": "660",
    "iso_3166-2": "ISO 3166-2:AI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Antarctica",
    alpha_2: "AQ",
    alpha_3: "ATA",
    "country-code": "010",
    "iso_3166-2": "ISO 3166-2:AQ",
    region: "",
    "sub-region": "",
    "intermediate-region": "",
    "region-code": "",
    "sub-region-code": "",
    "intermediate-region-code": "",
  },
  {
    name: "Antigua and Barbuda",
    alpha_2: "AG",
    alpha_3: "ATG",
    "country-code": "028",
    "iso_3166-2": "ISO 3166-2:AG",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Argentina",
    alpha_2: "AR",
    alpha_3: "ARG",
    "country-code": "032",
    "iso_3166-2": "ISO 3166-2:AR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Armenia",
    alpha_2: "AM",
    alpha_3: "ARM",
    "country-code": "051",
    "iso_3166-2": "ISO 3166-2:AM",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Aruba",
    alpha_2: "AW",
    alpha_3: "ABW",
    "country-code": "533",
    "iso_3166-2": "ISO 3166-2:AW",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Australia",
    alpha_2: "AU",
    alpha_3: "AUS",
    "country-code": "036",
    "iso_3166-2": "ISO 3166-2:AU",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Austria",
    alpha_2: "AT",
    alpha_3: "AUT",
    "country-code": "040",
    "iso_3166-2": "ISO 3166-2:AT",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Azerbaijan",
    alpha_2: "AZ",
    alpha_3: "AZE",
    "country-code": "031",
    "iso_3166-2": "ISO 3166-2:AZ",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Bahamas",
    alpha_2: "BS",
    alpha_3: "BHS",
    "country-code": "044",
    "iso_3166-2": "ISO 3166-2:BS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Bahrain",
    alpha_2: "BH",
    alpha_3: "BHR",
    "country-code": "048",
    "iso_3166-2": "ISO 3166-2:BH",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Bangladesh",
    alpha_2: "BD",
    alpha_3: "BGD",
    "country-code": "050",
    "iso_3166-2": "ISO 3166-2:BD",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Barbados",
    alpha_2: "BB",
    alpha_3: "BRB",
    "country-code": "052",
    "iso_3166-2": "ISO 3166-2:BB",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Belarus",
    alpha_2: "BY",
    alpha_3: "BLR",
    "country-code": "112",
    "iso_3166-2": "ISO 3166-2:BY",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Belgium",
    alpha_2: "BE",
    alpha_3: "BEL",
    "country-code": "056",
    "iso_3166-2": "ISO 3166-2:BE",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Belize",
    alpha_2: "BZ",
    alpha_3: "BLZ",
    "country-code": "084",
    "iso_3166-2": "ISO 3166-2:BZ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Benin",
    alpha_2: "BJ",
    alpha_3: "BEN",
    "country-code": "204",
    "iso_3166-2": "ISO 3166-2:BJ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Bermuda",
    alpha_2: "BM",
    alpha_3: "BMU",
    "country-code": "060",
    "iso_3166-2": "ISO 3166-2:BM",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Bhutan",
    alpha_2: "BT",
    alpha_3: "BTN",
    "country-code": "064",
    "iso_3166-2": "ISO 3166-2:BT",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Bolivia (Plurinational State of)",
    alpha_2: "BO",
    alpha_3: "BOL",
    "country-code": "068",
    "iso_3166-2": "ISO 3166-2:BO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha_2: "BQ",
    alpha_3: "BES",
    "country-code": "535",
    "iso_3166-2": "ISO 3166-2:BQ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Bosnia and Herzegovina",
    alpha_2: "BA",
    alpha_3: "BIH",
    "country-code": "070",
    "iso_3166-2": "ISO 3166-2:BA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Botswana",
    alpha_2: "BW",
    alpha_3: "BWA",
    "country-code": "072",
    "iso_3166-2": "ISO 3166-2:BW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Bouvet Island",
    alpha_2: "BV",
    alpha_3: "BVT",
    "country-code": "074",
    "iso_3166-2": "ISO 3166-2:BV",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Brazil",
    alpha_2: "BR",
    alpha_3: "BRA",
    "country-code": "076",
    "iso_3166-2": "ISO 3166-2:BR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "British Indian Ocean Territory",
    alpha_2: "IO",
    alpha_3: "IOT",
    "country-code": "086",
    "iso_3166-2": "ISO 3166-2:IO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Brunei Darussalam",
    alpha_2: "BN",
    alpha_3: "BRN",
    "country-code": "096",
    "iso_3166-2": "ISO 3166-2:BN",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Bulgaria",
    alpha_2: "BG",
    alpha_3: "BGR",
    "country-code": "100",
    "iso_3166-2": "ISO 3166-2:BG",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Burkina Faso",
    alpha_2: "BF",
    alpha_3: "BFA",
    "country-code": "854",
    "iso_3166-2": "ISO 3166-2:BF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Burundi",
    alpha_2: "BI",
    alpha_3: "BDI",
    "country-code": "108",
    "iso_3166-2": "ISO 3166-2:BI",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Cabo Verde",
    alpha_2: "CV",
    alpha_3: "CPV",
    "country-code": "132",
    "iso_3166-2": "ISO 3166-2:CV",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Cambodia",
    alpha_2: "KH",
    alpha_3: "KHM",
    "country-code": "116",
    "iso_3166-2": "ISO 3166-2:KH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Cameroon",
    alpha_2: "CM",
    alpha_3: "CMR",
    "country-code": "120",
    "iso_3166-2": "ISO 3166-2:CM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Canada",
    alpha_2: "CA",
    alpha_3: "CAN",
    "country-code": "124",
    "iso_3166-2": "ISO 3166-2:CA",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Cayman Islands",
    alpha_2: "KY",
    alpha_3: "CYM",
    "country-code": "136",
    "iso_3166-2": "ISO 3166-2:KY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Central African Republic",
    alpha_2: "CF",
    alpha_3: "CAF",
    "country-code": "140",
    "iso_3166-2": "ISO 3166-2:CF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Chad",
    alpha_2: "TD",
    alpha_3: "TCD",
    "country-code": "148",
    "iso_3166-2": "ISO 3166-2:TD",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Chile",
    alpha_2: "CL",
    alpha_3: "CHL",
    "country-code": "152",
    "iso_3166-2": "ISO 3166-2:CL",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "China",
    alpha_2: "CN",
    alpha_3: "CHN",
    "country-code": "156",
    "iso_3166-2": "ISO 3166-2:CN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Christmas Island",
    alpha_2: "CX",
    alpha_3: "CXR",
    "country-code": "162",
    "iso_3166-2": "ISO 3166-2:CX",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha_2: "CC",
    alpha_3: "CCK",
    "country-code": "166",
    "iso_3166-2": "ISO 3166-2:CC",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Colombia",
    alpha_2: "CO",
    alpha_3: "COL",
    "country-code": "170",
    "iso_3166-2": "ISO 3166-2:CO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Comoros",
    alpha_2: "KM",
    alpha_3: "COM",
    "country-code": "174",
    "iso_3166-2": "ISO 3166-2:KM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Congo",
    alpha_2: "CG",
    alpha_3: "COG",
    "country-code": "178",
    "iso_3166-2": "ISO 3166-2:CG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Congo, Democratic Republic of the",
    alpha_2: "CD",
    alpha_3: "COD",
    "country-code": "180",
    "iso_3166-2": "ISO 3166-2:CD",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Cook Islands",
    alpha_2: "CK",
    alpha_3: "COK",
    "country-code": "184",
    "iso_3166-2": "ISO 3166-2:CK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Costa Rica",
    alpha_2: "CR",
    alpha_3: "CRI",
    "country-code": "188",
    "iso_3166-2": "ISO 3166-2:CR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Côte d'Ivoire",
    alpha_2: "CI",
    alpha_3: "CIV",
    "country-code": "384",
    "iso_3166-2": "ISO 3166-2:CI",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Croatia",
    alpha_2: "HR",
    alpha_3: "HRV",
    "country-code": "191",
    "iso_3166-2": "ISO 3166-2:HR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Cuba",
    alpha_2: "CU",
    alpha_3: "CUB",
    "country-code": "192",
    "iso_3166-2": "ISO 3166-2:CU",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Curaçao",
    alpha_2: "CW",
    alpha_3: "CUW",
    "country-code": "531",
    "iso_3166-2": "ISO 3166-2:CW",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Cyprus",
    alpha_2: "CY",
    alpha_3: "CYP",
    "country-code": "196",
    "iso_3166-2": "ISO 3166-2:CY",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Czechia",
    alpha_2: "CZ",
    alpha_3: "CZE",
    "country-code": "203",
    "iso_3166-2": "ISO 3166-2:CZ",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Denmark",
    alpha_2: "DK",
    alpha_3: "DNK",
    "country-code": "208",
    "iso_3166-2": "ISO 3166-2:DK",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Djibouti",
    alpha_2: "DJ",
    alpha_3: "DJI",
    "country-code": "262",
    "iso_3166-2": "ISO 3166-2:DJ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Dominica",
    alpha_2: "DM",
    alpha_3: "DMA",
    "country-code": "212",
    "iso_3166-2": "ISO 3166-2:DM",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Dominican Republic",
    alpha_2: "DO",
    alpha_3: "DOM",
    "country-code": "214",
    "iso_3166-2": "ISO 3166-2:DO",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Ecuador",
    alpha_2: "EC",
    alpha_3: "ECU",
    "country-code": "218",
    "iso_3166-2": "ISO 3166-2:EC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Egypt",
    alpha_2: "EG",
    alpha_3: "EGY",
    "country-code": "818",
    "iso_3166-2": "ISO 3166-2:EG",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "El Salvador",
    alpha_2: "SV",
    alpha_3: "SLV",
    "country-code": "222",
    "iso_3166-2": "ISO 3166-2:SV",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Equatorial Guinea",
    alpha_2: "GQ",
    alpha_3: "GNQ",
    "country-code": "226",
    "iso_3166-2": "ISO 3166-2:GQ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Eritrea",
    alpha_2: "ER",
    alpha_3: "ERI",
    "country-code": "232",
    "iso_3166-2": "ISO 3166-2:ER",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Estonia",
    alpha_2: "EE",
    alpha_3: "EST",
    "country-code": "233",
    "iso_3166-2": "ISO 3166-2:EE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Eswatini",
    alpha_2: "SZ",
    alpha_3: "SWZ",
    "country-code": "748",
    "iso_3166-2": "ISO 3166-2:SZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Ethiopia",
    alpha_2: "ET",
    alpha_3: "ETH",
    "country-code": "231",
    "iso_3166-2": "ISO 3166-2:ET",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha_2: "FK",
    alpha_3: "FLK",
    "country-code": "238",
    "iso_3166-2": "ISO 3166-2:FK",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Faroe Islands",
    alpha_2: "FO",
    alpha_3: "FRO",
    "country-code": "234",
    "iso_3166-2": "ISO 3166-2:FO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Fiji",
    alpha_2: "FJ",
    alpha_3: "FJI",
    "country-code": "242",
    "iso_3166-2": "ISO 3166-2:FJ",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Finland",
    alpha_2: "FI",
    alpha_3: "FIN",
    "country-code": "246",
    "iso_3166-2": "ISO 3166-2:FI",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "France",
    alpha_2: "FR",
    alpha_3: "FRA",
    "country-code": "250",
    "iso_3166-2": "ISO 3166-2:FR",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "French Guiana",
    alpha_2: "GF",
    alpha_3: "GUF",
    "country-code": "254",
    "iso_3166-2": "ISO 3166-2:GF",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "French Polynesia",
    alpha_2: "PF",
    alpha_3: "PYF",
    "country-code": "258",
    "iso_3166-2": "ISO 3166-2:PF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "French Southern Territories",
    alpha_2: "TF",
    alpha_3: "ATF",
    "country-code": "260",
    "iso_3166-2": "ISO 3166-2:TF",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Gabon",
    alpha_2: "GA",
    alpha_3: "GAB",
    "country-code": "266",
    "iso_3166-2": "ISO 3166-2:GA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Gambia",
    alpha_2: "GM",
    alpha_3: "GMB",
    "country-code": "270",
    "iso_3166-2": "ISO 3166-2:GM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Georgia",
    alpha_2: "GE",
    alpha_3: "GEO",
    "country-code": "268",
    "iso_3166-2": "ISO 3166-2:GE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Germany",
    alpha_2: "DE",
    alpha_3: "DEU",
    "country-code": "276",
    "iso_3166-2": "ISO 3166-2:DE",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Ghana",
    alpha_2: "GH",
    alpha_3: "GHA",
    "country-code": "288",
    "iso_3166-2": "ISO 3166-2:GH",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Gibraltar",
    alpha_2: "GI",
    alpha_3: "GIB",
    "country-code": "292",
    "iso_3166-2": "ISO 3166-2:GI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Greece",
    alpha_2: "GR",
    alpha_3: "GRC",
    "country-code": "300",
    "iso_3166-2": "ISO 3166-2:GR",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Greenland",
    alpha_2: "GL",
    alpha_3: "GRL",
    "country-code": "304",
    "iso_3166-2": "ISO 3166-2:GL",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Grenada",
    alpha_2: "GD",
    alpha_3: "GRD",
    "country-code": "308",
    "iso_3166-2": "ISO 3166-2:GD",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Guadeloupe",
    alpha_2: "GP",
    alpha_3: "GLP",
    "country-code": "312",
    "iso_3166-2": "ISO 3166-2:GP",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Guam",
    alpha_2: "GU",
    alpha_3: "GUM",
    "country-code": "316",
    "iso_3166-2": "ISO 3166-2:GU",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Guatemala",
    alpha_2: "GT",
    alpha_3: "GTM",
    "country-code": "320",
    "iso_3166-2": "ISO 3166-2:GT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Guernsey",
    alpha_2: "GG",
    alpha_3: "GGY",
    "country-code": "831",
    "iso_3166-2": "ISO 3166-2:GG",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "830",
  },
  {
    name: "Guinea",
    alpha_2: "GN",
    alpha_3: "GIN",
    "country-code": "324",
    "iso_3166-2": "ISO 3166-2:GN",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Guinea-Bissau",
    alpha_2: "GW",
    alpha_3: "GNB",
    "country-code": "624",
    "iso_3166-2": "ISO 3166-2:GW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Guyana",
    alpha_2: "GY",
    alpha_3: "GUY",
    "country-code": "328",
    "iso_3166-2": "ISO 3166-2:GY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Haiti",
    alpha_2: "HT",
    alpha_3: "HTI",
    "country-code": "332",
    "iso_3166-2": "ISO 3166-2:HT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Heard Island and McDonald Islands",
    alpha_2: "HM",
    alpha_3: "HMD",
    "country-code": "334",
    "iso_3166-2": "ISO 3166-2:HM",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Holy See",
    alpha_2: "VA",
    alpha_3: "VAT",
    "country-code": "336",
    "iso_3166-2": "ISO 3166-2:VA",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Honduras",
    alpha_2: "HN",
    alpha_3: "HND",
    "country-code": "340",
    "iso_3166-2": "ISO 3166-2:HN",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Hong Kong",
    alpha_2: "HK",
    alpha_3: "HKG",
    "country-code": "344",
    "iso_3166-2": "ISO 3166-2:HK",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Hungary",
    alpha_2: "HU",
    alpha_3: "HUN",
    "country-code": "348",
    "iso_3166-2": "ISO 3166-2:HU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Iceland",
    alpha_2: "IS",
    alpha_3: "ISL",
    "country-code": "352",
    "iso_3166-2": "ISO 3166-2:IS",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "India",
    alpha_2: "IN",
    alpha_3: "IND",
    "country-code": "356",
    "iso_3166-2": "ISO 3166-2:IN",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Indonesia",
    alpha_2: "ID",
    alpha_3: "IDN",
    "country-code": "360",
    "iso_3166-2": "ISO 3166-2:ID",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Iran (Islamic Republic of)",
    alpha_2: "IR",
    alpha_3: "IRN",
    "country-code": "364",
    "iso_3166-2": "ISO 3166-2:IR",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Iraq",
    alpha_2: "IQ",
    alpha_3: "IRQ",
    "country-code": "368",
    "iso_3166-2": "ISO 3166-2:IQ",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Ireland",
    alpha_2: "IE",
    alpha_3: "IRL",
    "country-code": "372",
    "iso_3166-2": "ISO 3166-2:IE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Isle of Man",
    alpha_2: "IM",
    alpha_3: "IMN",
    "country-code": "833",
    "iso_3166-2": "ISO 3166-2:IM",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Israel",
    alpha_2: "IL",
    alpha_3: "ISR",
    "country-code": "376",
    "iso_3166-2": "ISO 3166-2:IL",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Italy",
    alpha_2: "IT",
    alpha_3: "ITA",
    "country-code": "380",
    "iso_3166-2": "ISO 3166-2:IT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Jamaica",
    alpha_2: "JM",
    alpha_3: "JAM",
    "country-code": "388",
    "iso_3166-2": "ISO 3166-2:JM",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Japan",
    alpha_2: "JP",
    alpha_3: "JPN",
    "country-code": "392",
    "iso_3166-2": "ISO 3166-2:JP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Jersey",
    alpha_2: "JE",
    alpha_3: "JEY",
    "country-code": "832",
    "iso_3166-2": "ISO 3166-2:JE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "830",
  },
  {
    name: "Jordan",
    alpha_2: "JO",
    alpha_3: "JOR",
    "country-code": "400",
    "iso_3166-2": "ISO 3166-2:JO",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Kazakhstan",
    alpha_2: "KZ",
    alpha_3: "KAZ",
    "country-code": "398",
    "iso_3166-2": "ISO 3166-2:KZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Kenya",
    alpha_2: "KE",
    alpha_3: "KEN",
    "country-code": "404",
    "iso_3166-2": "ISO 3166-2:KE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Kiribati",
    alpha_2: "KI",
    alpha_3: "KIR",
    "country-code": "296",
    "iso_3166-2": "ISO 3166-2:KI",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha_2: "KP",
    alpha_3: "PRK",
    "country-code": "408",
    "iso_3166-2": "ISO 3166-2:KP",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Korea, Republic of",
    alpha_2: "KR",
    alpha_3: "KOR",
    "country-code": "410",
    "iso_3166-2": "ISO 3166-2:KR",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Kuwait",
    alpha_2: "KW",
    alpha_3: "KWT",
    "country-code": "414",
    "iso_3166-2": "ISO 3166-2:KW",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Kyrgyzstan",
    alpha_2: "KG",
    alpha_3: "KGZ",
    "country-code": "417",
    "iso_3166-2": "ISO 3166-2:KG",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Lao People's Democratic Republic",
    alpha_2: "LA",
    alpha_3: "LAO",
    "country-code": "418",
    "iso_3166-2": "ISO 3166-2:LA",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Latvia",
    alpha_2: "LV",
    alpha_3: "LVA",
    "country-code": "428",
    "iso_3166-2": "ISO 3166-2:LV",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Lebanon",
    alpha_2: "LB",
    alpha_3: "LBN",
    "country-code": "422",
    "iso_3166-2": "ISO 3166-2:LB",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Lesotho",
    alpha_2: "LS",
    alpha_3: "LSO",
    "country-code": "426",
    "iso_3166-2": "ISO 3166-2:LS",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Liberia",
    alpha_2: "LR",
    alpha_3: "LBR",
    "country-code": "430",
    "iso_3166-2": "ISO 3166-2:LR",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Libya",
    alpha_2: "LY",
    alpha_3: "LBY",
    "country-code": "434",
    "iso_3166-2": "ISO 3166-2:LY",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Liechtenstein",
    alpha_2: "LI",
    alpha_3: "LIE",
    "country-code": "438",
    "iso_3166-2": "ISO 3166-2:LI",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Lithuania",
    alpha_2: "LT",
    alpha_3: "LTU",
    "country-code": "440",
    "iso_3166-2": "ISO 3166-2:LT",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Luxembourg",
    alpha_2: "LU",
    alpha_3: "LUX",
    "country-code": "442",
    "iso_3166-2": "ISO 3166-2:LU",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Macao",
    alpha_2: "MO",
    alpha_3: "MAC",
    "country-code": "446",
    "iso_3166-2": "ISO 3166-2:MO",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Madagascar",
    alpha_2: "MG",
    alpha_3: "MDG",
    "country-code": "450",
    "iso_3166-2": "ISO 3166-2:MG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Malawi",
    alpha_2: "MW",
    alpha_3: "MWI",
    "country-code": "454",
    "iso_3166-2": "ISO 3166-2:MW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Malaysia",
    alpha_2: "MY",
    alpha_3: "MYS",
    "country-code": "458",
    "iso_3166-2": "ISO 3166-2:MY",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Maldives",
    alpha_2: "MV",
    alpha_3: "MDV",
    "country-code": "462",
    "iso_3166-2": "ISO 3166-2:MV",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Mali",
    alpha_2: "ML",
    alpha_3: "MLI",
    "country-code": "466",
    "iso_3166-2": "ISO 3166-2:ML",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Malta",
    alpha_2: "MT",
    alpha_3: "MLT",
    "country-code": "470",
    "iso_3166-2": "ISO 3166-2:MT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Marshall Islands",
    alpha_2: "MH",
    alpha_3: "MHL",
    "country-code": "584",
    "iso_3166-2": "ISO 3166-2:MH",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Martinique",
    alpha_2: "MQ",
    alpha_3: "MTQ",
    "country-code": "474",
    "iso_3166-2": "ISO 3166-2:MQ",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Mauritania",
    alpha_2: "MR",
    alpha_3: "MRT",
    "country-code": "478",
    "iso_3166-2": "ISO 3166-2:MR",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Mauritius",
    alpha_2: "MU",
    alpha_3: "MUS",
    "country-code": "480",
    "iso_3166-2": "ISO 3166-2:MU",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Mayotte",
    alpha_2: "YT",
    alpha_3: "MYT",
    "country-code": "175",
    "iso_3166-2": "ISO 3166-2:YT",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Mexico",
    alpha_2: "MX",
    alpha_3: "MEX",
    "country-code": "484",
    "iso_3166-2": "ISO 3166-2:MX",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Micronesia (Federated States of)",
    alpha_2: "FM",
    alpha_3: "FSM",
    "country-code": "583",
    "iso_3166-2": "ISO 3166-2:FM",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Moldova, Republic of",
    alpha_2: "MD",
    alpha_3: "MDA",
    "country-code": "498",
    "iso_3166-2": "ISO 3166-2:MD",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Monaco",
    alpha_2: "MC",
    alpha_3: "MCO",
    "country-code": "492",
    "iso_3166-2": "ISO 3166-2:MC",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Mongolia",
    alpha_2: "MN",
    alpha_3: "MNG",
    "country-code": "496",
    "iso_3166-2": "ISO 3166-2:MN",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Montenegro",
    alpha_2: "ME",
    alpha_3: "MNE",
    "country-code": "499",
    "iso_3166-2": "ISO 3166-2:ME",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Montserrat",
    alpha_2: "MS",
    alpha_3: "MSR",
    "country-code": "500",
    "iso_3166-2": "ISO 3166-2:MS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Morocco",
    alpha_2: "MA",
    alpha_3: "MAR",
    "country-code": "504",
    "iso_3166-2": "ISO 3166-2:MA",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Mozambique",
    alpha_2: "MZ",
    alpha_3: "MOZ",
    "country-code": "508",
    "iso_3166-2": "ISO 3166-2:MZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Myanmar",
    alpha_2: "MM",
    alpha_3: "MMR",
    "country-code": "104",
    "iso_3166-2": "ISO 3166-2:MM",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Namibia",
    alpha_2: "NA",
    alpha_3: "NAM",
    "country-code": "516",
    "iso_3166-2": "ISO 3166-2:NA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "Nauru",
    alpha_2: "NR",
    alpha_3: "NRU",
    "country-code": "520",
    "iso_3166-2": "ISO 3166-2:NR",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Nepal",
    alpha_2: "NP",
    alpha_3: "NPL",
    "country-code": "524",
    "iso_3166-2": "ISO 3166-2:NP",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Netherlands",
    alpha_2: "NL",
    alpha_3: "NLD",
    "country-code": "528",
    "iso_3166-2": "ISO 3166-2:NL",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "New Caledonia",
    alpha_2: "NC",
    alpha_3: "NCL",
    "country-code": "540",
    "iso_3166-2": "ISO 3166-2:NC",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "New Zealand",
    alpha_2: "NZ",
    alpha_3: "NZL",
    "country-code": "554",
    "iso_3166-2": "ISO 3166-2:NZ",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "Nicaragua",
    alpha_2: "NI",
    alpha_3: "NIC",
    "country-code": "558",
    "iso_3166-2": "ISO 3166-2:NI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Niger",
    alpha_2: "NE",
    alpha_3: "NER",
    "country-code": "562",
    "iso_3166-2": "ISO 3166-2:NE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Nigeria",
    alpha_2: "NG",
    alpha_3: "NGA",
    "country-code": "566",
    "iso_3166-2": "ISO 3166-2:NG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Niue",
    alpha_2: "NU",
    alpha_3: "NIU",
    "country-code": "570",
    "iso_3166-2": "ISO 3166-2:NU",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Norfolk Island",
    alpha_2: "NF",
    alpha_3: "NFK",
    "country-code": "574",
    "iso_3166-2": "ISO 3166-2:NF",
    region: "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": "",
  },
  {
    name: "North Macedonia",
    alpha_2: "MK",
    alpha_3: "MKD",
    "country-code": "807",
    "iso_3166-2": "ISO 3166-2:MK",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Northern Mariana Islands",
    alpha_2: "MP",
    alpha_3: "MNP",
    "country-code": "580",
    "iso_3166-2": "ISO 3166-2:MP",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Norway",
    alpha_2: "NO",
    alpha_3: "NOR",
    "country-code": "578",
    "iso_3166-2": "ISO 3166-2:NO",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Oman",
    alpha_2: "OM",
    alpha_3: "OMN",
    "country-code": "512",
    "iso_3166-2": "ISO 3166-2:OM",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Pakistan",
    alpha_2: "PK",
    alpha_3: "PAK",
    "country-code": "586",
    "iso_3166-2": "ISO 3166-2:PK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Palau",
    alpha_2: "PW",
    alpha_3: "PLW",
    "country-code": "585",
    "iso_3166-2": "ISO 3166-2:PW",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Palestine, State of",
    alpha_2: "PS",
    alpha_3: "PSE",
    "country-code": "275",
    "iso_3166-2": "ISO 3166-2:PS",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Panama",
    alpha_2: "PA",
    alpha_3: "PAN",
    "country-code": "591",
    "iso_3166-2": "ISO 3166-2:PA",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013",
  },
  {
    name: "Papua New Guinea",
    alpha_2: "PG",
    alpha_3: "PNG",
    "country-code": "598",
    "iso_3166-2": "ISO 3166-2:PG",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Paraguay",
    alpha_2: "PY",
    alpha_3: "PRY",
    "country-code": "600",
    "iso_3166-2": "ISO 3166-2:PY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Peru",
    alpha_2: "PE",
    alpha_3: "PER",
    "country-code": "604",
    "iso_3166-2": "ISO 3166-2:PE",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Philippines",
    alpha_2: "PH",
    alpha_3: "PHL",
    "country-code": "608",
    "iso_3166-2": "ISO 3166-2:PH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Pitcairn",
    alpha_2: "PN",
    alpha_3: "PCN",
    "country-code": "612",
    "iso_3166-2": "ISO 3166-2:PN",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Poland",
    alpha_2: "PL",
    alpha_3: "POL",
    "country-code": "616",
    "iso_3166-2": "ISO 3166-2:PL",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Portugal",
    alpha_2: "PT",
    alpha_3: "PRT",
    "country-code": "620",
    "iso_3166-2": "ISO 3166-2:PT",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Puerto Rico",
    alpha_2: "PR",
    alpha_3: "PRI",
    "country-code": "630",
    "iso_3166-2": "ISO 3166-2:PR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Qatar",
    alpha_2: "QA",
    alpha_3: "QAT",
    "country-code": "634",
    "iso_3166-2": "ISO 3166-2:QA",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Réunion",
    alpha_2: "RE",
    alpha_3: "REU",
    "country-code": "638",
    "iso_3166-2": "ISO 3166-2:RE",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Romania",
    alpha_2: "RO",
    alpha_3: "ROU",
    "country-code": "642",
    "iso_3166-2": "ISO 3166-2:RO",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Russian Federation",
    alpha_2: "RU",
    alpha_3: "RUS",
    "country-code": "643",
    "iso_3166-2": "ISO 3166-2:RU",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Rwanda",
    alpha_2: "RW",
    alpha_3: "RWA",
    "country-code": "646",
    "iso_3166-2": "ISO 3166-2:RW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Saint Barthélemy",
    alpha_2: "BL",
    alpha_3: "BLM",
    "country-code": "652",
    "iso_3166-2": "ISO 3166-2:BL",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha_2: "SH",
    alpha_3: "SHN",
    "country-code": "654",
    "iso_3166-2": "ISO 3166-2:SH",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Saint Kitts and Nevis",
    alpha_2: "KN",
    alpha_3: "KNA",
    "country-code": "659",
    "iso_3166-2": "ISO 3166-2:KN",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Lucia",
    alpha_2: "LC",
    alpha_3: "LCA",
    "country-code": "662",
    "iso_3166-2": "ISO 3166-2:LC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Martin (French part)",
    alpha_2: "MF",
    alpha_3: "MAF",
    "country-code": "663",
    "iso_3166-2": "ISO 3166-2:MF",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha_2: "PM",
    alpha_3: "SPM",
    "country-code": "666",
    "iso_3166-2": "ISO 3166-2:PM",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha_2: "VC",
    alpha_3: "VCT",
    "country-code": "670",
    "iso_3166-2": "ISO 3166-2:VC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Samoa",
    alpha_2: "WS",
    alpha_3: "WSM",
    "country-code": "882",
    "iso_3166-2": "ISO 3166-2:WS",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "San Marino",
    alpha_2: "SM",
    alpha_3: "SMR",
    "country-code": "674",
    "iso_3166-2": "ISO 3166-2:SM",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Sao Tome and Principe",
    alpha_2: "ST",
    alpha_3: "STP",
    "country-code": "678",
    "iso_3166-2": "ISO 3166-2:ST",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017",
  },
  {
    name: "Saudi Arabia",
    alpha_2: "SA",
    alpha_3: "SAU",
    "country-code": "682",
    "iso_3166-2": "ISO 3166-2:SA",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Senegal",
    alpha_2: "SN",
    alpha_3: "SEN",
    "country-code": "686",
    "iso_3166-2": "ISO 3166-2:SN",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Serbia",
    alpha_2: "RS",
    alpha_3: "SRB",
    "country-code": "688",
    "iso_3166-2": "ISO 3166-2:RS",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Seychelles",
    alpha_2: "SC",
    alpha_3: "SYC",
    "country-code": "690",
    "iso_3166-2": "ISO 3166-2:SC",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Sierra Leone",
    alpha_2: "SL",
    alpha_3: "SLE",
    "country-code": "694",
    "iso_3166-2": "ISO 3166-2:SL",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Singapore",
    alpha_2: "SG",
    alpha_3: "SGP",
    "country-code": "702",
    "iso_3166-2": "ISO 3166-2:SG",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha_2: "SX",
    alpha_3: "SXM",
    "country-code": "534",
    "iso_3166-2": "ISO 3166-2:SX",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Slovakia",
    alpha_2: "SK",
    alpha_3: "SVK",
    "country-code": "703",
    "iso_3166-2": "ISO 3166-2:SK",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "Slovenia",
    alpha_2: "SI",
    alpha_3: "SVN",
    "country-code": "705",
    "iso_3166-2": "ISO 3166-2:SI",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Solomon Islands",
    alpha_2: "SB",
    alpha_3: "SLB",
    "country-code": "090",
    "iso_3166-2": "ISO 3166-2:SB",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Somalia",
    alpha_2: "SO",
    alpha_3: "SOM",
    "country-code": "706",
    "iso_3166-2": "ISO 3166-2:SO",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "South Africa",
    alpha_2: "ZA",
    alpha_3: "ZAF",
    "country-code": "710",
    "iso_3166-2": "ISO 3166-2:ZA",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha_2: "GS",
    alpha_3: "SGS",
    "country-code": "239",
    "iso_3166-2": "ISO 3166-2:GS",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "South Sudan",
    alpha_2: "SS",
    alpha_3: "SSD",
    "country-code": "728",
    "iso_3166-2": "ISO 3166-2:SS",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Spain",
    alpha_2: "ES",
    alpha_3: "ESP",
    "country-code": "724",
    "iso_3166-2": "ISO 3166-2:ES",
    region: "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": "",
  },
  {
    name: "Sri Lanka",
    alpha_2: "LK",
    alpha_3: "LKA",
    "country-code": "144",
    "iso_3166-2": "ISO 3166-2:LK",
    region: "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": "",
  },
  {
    name: "Sudan",
    alpha_2: "SD",
    alpha_3: "SDN",
    "country-code": "729",
    "iso_3166-2": "ISO 3166-2:SD",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Suriname",
    alpha_2: "SR",
    alpha_3: "SUR",
    "country-code": "740",
    "iso_3166-2": "ISO 3166-2:SR",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha_2: "SJ",
    alpha_3: "SJM",
    "country-code": "744",
    "iso_3166-2": "ISO 3166-2:SJ",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Sweden",
    alpha_2: "SE",
    alpha_3: "SWE",
    "country-code": "752",
    "iso_3166-2": "ISO 3166-2:SE",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "Switzerland",
    alpha_2: "CH",
    alpha_3: "CHE",
    "country-code": "756",
    "iso_3166-2": "ISO 3166-2:CH",
    region: "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": "",
  },
  {
    name: "Syrian Arab Republic",
    alpha_2: "SY",
    alpha_3: "SYR",
    "country-code": "760",
    "iso_3166-2": "ISO 3166-2:SY",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Taiwan, Province of China",
    alpha_2: "TW",
    alpha_3: "TWN",
    "country-code": "158",
    "iso_3166-2": "ISO 3166-2:TW",
    region: "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": "",
  },
  {
    name: "Tajikistan",
    alpha_2: "TJ",
    alpha_3: "TJK",
    "country-code": "762",
    "iso_3166-2": "ISO 3166-2:TJ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Tanzania, United Republic of",
    alpha_2: "TZ",
    alpha_3: "TZA",
    "country-code": "834",
    "iso_3166-2": "ISO 3166-2:TZ",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Thailand",
    alpha_2: "TH",
    alpha_3: "THA",
    "country-code": "764",
    "iso_3166-2": "ISO 3166-2:TH",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Timor-Leste",
    alpha_2: "TL",
    alpha_3: "TLS",
    "country-code": "626",
    "iso_3166-2": "ISO 3166-2:TL",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Togo",
    alpha_2: "TG",
    alpha_3: "TGO",
    "country-code": "768",
    "iso_3166-2": "ISO 3166-2:TG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011",
  },
  {
    name: "Tokelau",
    alpha_2: "TK",
    alpha_3: "TKL",
    "country-code": "772",
    "iso_3166-2": "ISO 3166-2:TK",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Tonga",
    alpha_2: "TO",
    alpha_3: "TON",
    "country-code": "776",
    "iso_3166-2": "ISO 3166-2:TO",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Trinidad and Tobago",
    alpha_2: "TT",
    alpha_3: "TTO",
    "country-code": "780",
    "iso_3166-2": "ISO 3166-2:TT",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Tunisia",
    alpha_2: "TN",
    alpha_3: "TUN",
    "country-code": "788",
    "iso_3166-2": "ISO 3166-2:TN",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Turkey",
    alpha_2: "TR",
    alpha_3: "TUR",
    "country-code": "792",
    "iso_3166-2": "ISO 3166-2:TR",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Turkmenistan",
    alpha_2: "TM",
    alpha_3: "TKM",
    "country-code": "795",
    "iso_3166-2": "ISO 3166-2:TM",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Turks and Caicos Islands",
    alpha_2: "TC",
    alpha_3: "TCA",
    "country-code": "796",
    "iso_3166-2": "ISO 3166-2:TC",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Tuvalu",
    alpha_2: "TV",
    alpha_3: "TUV",
    "country-code": "798",
    "iso_3166-2": "ISO 3166-2:TV",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Uganda",
    alpha_2: "UG",
    alpha_3: "UGA",
    "country-code": "800",
    "iso_3166-2": "ISO 3166-2:UG",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Ukraine",
    alpha_2: "UA",
    alpha_3: "UKR",
    "country-code": "804",
    "iso_3166-2": "ISO 3166-2:UA",
    region: "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": "",
  },
  {
    name: "United Arab Emirates",
    alpha_2: "AE",
    alpha_3: "ARE",
    "country-code": "784",
    "iso_3166-2": "ISO 3166-2:AE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    alpha_2: "GB",
    alpha_3: "GBR",
    "country-code": "826",
    "iso_3166-2": "ISO 3166-2:GB",
    region: "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "",
  },
  {
    name: "United States of America",
    alpha_2: "US",
    alpha_3: "USA",
    "country-code": "840",
    "iso_3166-2": "ISO 3166-2:US",
    region: "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": "",
  },
  {
    name: "United States Minor Outlying Islands",
    alpha_2: "UM",
    alpha_3: "UMI",
    "country-code": "581",
    "iso_3166-2": "ISO 3166-2:UM",
    region: "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": "",
  },
  {
    name: "Uruguay",
    alpha_2: "UY",
    alpha_3: "URY",
    "country-code": "858",
    "iso_3166-2": "ISO 3166-2:UY",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Uzbekistan",
    alpha_2: "UZ",
    alpha_3: "UZB",
    "country-code": "860",
    "iso_3166-2": "ISO 3166-2:UZ",
    region: "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": "",
  },
  {
    name: "Vanuatu",
    alpha_2: "VU",
    alpha_3: "VUT",
    "country-code": "548",
    "iso_3166-2": "ISO 3166-2:VU",
    region: "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": "",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    alpha_2: "VE",
    alpha_3: "VEN",
    "country-code": "862",
    "iso_3166-2": "ISO 3166-2:VE",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005",
  },
  {
    name: "Viet Nam",
    alpha_2: "VN",
    alpha_3: "VNM",
    "country-code": "704",
    "iso_3166-2": "ISO 3166-2:VN",
    region: "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": "",
  },
  {
    name: "Virgin Islands (British)",
    alpha_2: "VG",
    alpha_3: "VGB",
    "country-code": "092",
    "iso_3166-2": "ISO 3166-2:VG",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Virgin Islands (U.S.)",
    alpha_2: "VI",
    alpha_3: "VIR",
    "country-code": "850",
    "iso_3166-2": "ISO 3166-2:VI",
    region: "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029",
  },
  {
    name: "Wallis and Futuna",
    alpha_2: "WF",
    alpha_3: "WLF",
    "country-code": "876",
    "iso_3166-2": "ISO 3166-2:WF",
    region: "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": "",
  },
  {
    name: "Western Sahara",
    alpha_2: "EH",
    alpha_3: "ESH",
    "country-code": "732",
    "iso_3166-2": "ISO 3166-2:EH",
    region: "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": "",
  },
  {
    name: "Yemen",
    alpha_2: "YE",
    alpha_3: "YEM",
    "country-code": "887",
    "iso_3166-2": "ISO 3166-2:YE",
    region: "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": "",
  },
  {
    name: "Zambia",
    alpha_2: "ZM",
    alpha_3: "ZMB",
    "country-code": "894",
    "iso_3166-2": "ISO 3166-2:ZM",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
  {
    name: "Zimbabwe",
    alpha_2: "ZW",
    alpha_3: "ZWE",
    "country-code": "716",
    "iso_3166-2": "ISO 3166-2:ZW",
    region: "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014",
  },
];
