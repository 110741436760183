import { Box, Stack, Typography } from "@mui/material";
import Translate from "src/components/translate";
import { DOMAIN_NAME } from "src/config";

const Message = ({ adminLogin }) => (
  <Stack direction="row" alignItems="flex-start" sx={{ mt: 4 }}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        sx={{ textAlign: "left", mb: 1, fontSize: "28px", fontWeight: "bold" }}
      >
        {adminLogin ? (
          <Translate>Hi,Welcome Back!</Translate>
        ) : (
          <Translate>Connect your wallet</Translate>
        )}
      </Typography>
      {adminLogin ? (
        <Typography sx={{ textAlign: "left", mb: 1, fontSize: "16px" }}>
          Sign in to <Translate>{DOMAIN_NAME}</Translate>
        </Typography>
      ) : (
        <>
          {" "}
          <Typography sx={{ textAlign: "left", mb: 1, fontSize: "16px" }}>
            <Translate>
              Connect with one of available wallet providers or create a new
              wallet.
            </Translate>
          </Typography>
        </>
      )}
    </Box>
  </Stack>
);

export default Message;
