import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useDataHandler from "src/components/data-handler/hooks/use-data-handler";
import usePagination from "src/components/pagination/usePagination";
import axiosInstance from "src/utils/fetchUser";
import serializeDate from "src/utils/serialize-date";

const useRankAchieversList = (filter = {}, newValue) => {
  const [state, actions] = useDataHandler();
  const { id } = useParams();

  const { count, onChange, page, seed, rowStart } = usePagination();
  const fetchData = async (page = 1, filter = {}) => {
    actions.loading();
    try {
      const { data, status } = await axiosInstance.get(
        `rank-achievers?rank_id=${
          newValue ? newValue : id !== undefined ? id : ""
        }`,
        {
          params: {
            page,
            ...filter,
          },
        }
      );
      if (status) {
        const { last_page, from, data: list } = data?.data;
        if (Boolean(list?.length)) {
          seed(last_page, from);
          actions.success(list);
          return;
        }
        actions.success([]);
      }
      actions.success([]);
    } catch (err) {
      actions.error();
      console.log(err);
    }
  };

  useEffect(() => {
    const { start_date, end_date, ...rest } = filter;
    fetchData(page, {
      start_date: serializeDate(start_date),
      end_date: serializeDate(end_date),
      ...rest,
    });
  }, [page]);
  return {
    state,
    fetchData,
    count,
    onChange,
    page,
    rowStart,
  };
};

export default useRankAchieversList;
