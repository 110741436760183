import moment from "moment/moment";

const swiftParseDate = (dateTime = null) => {
  if (dateTime) {
    return moment(dateTime)
      .format("DD MMM YYYY , hh:mm:ss A")
      .replace(",", "at");
  }
  return null;
};

export default swiftParseDate;
